/*
 * =====================================================================
 * 작 업 명  :
 * 파 일 명  : DriveGridList.vue
 * 변경이력
 * 2023-01-12 / 이성준 / 최초작성
 * =====================================================================
 */
<template>
    <ag-grid-vue
        class="ag-theme-alpine w-100"
        style="height: 400px"
        :columnDefs="columnDefs"
        :rowData="filteredItems"
        :suppressCellSelection="true"
        :defaultColDef="defaultColDef"
        :multiSortKey="multiSortKey"
        :rowSelection="rowSelection"
        @rowDoubleClicked="onEnter($event.data)"
        @cellContextMenu="onContextmenu($event.data, $event)"
        @grid-ready="onGridReady"
    />
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {AgGridVue} from "ag-grid-vue3";
import DriveGridListFolder from "@/views/drive/DriveGridListFolder.vue";
import DriveGridListFile from "@/views/drive/DriveGridListFile.vue";
import {mapGetters} from "vuex";

export default {
    name: "DriveGridList",
    components: {
        AgGridVue
    },
    props: {
        filteredItems: Object,
    },
    computed: {
        ...mapGetters("drive", [
            "clipboard",
            "clipboardMode",
        ]),
    },
    data() {
        return {
            gridApi: null,
            columnApi: null,
            columnDefs: this.getColumnDefs(),
            defaultColDef: {
                sortable: true,
                // resizable: true,
                flex: 1,
            },
            rowSelection: 'single',
            multiSortKey: 'ctrl',
        }
    },
    watch: {
        '$i18n.locale'() {
            this.setHeaderNames();
            this.gridApi.refreshHeader();
            this.gridApi.refreshCells();
        }
    },
    emits: ['onFormatDate','onEnter', 'onContextmenu'],
    methods: {
        formatDate(params) {
            let response;

            this.$emit('onFormatDate', params.value, formatDate => response = formatDate);

            return response;
        },
        formatFileSize(params) {
          if (params.data.FILE_TYPE === "FILE") {
            const fileSizeInBytes = params.value;
            const fileSizeInKB = fileSizeInBytes / 1024; // convert bytes to kilobytes

            return `${Math.ceil(fileSizeInKB)} KB`;
          } else {
            return "";
          }
        },
        onEnter(data) {
            this.$emit('onEnter', data);
        },
        onContextmenu(data, event) {
            this.$emit('onContextmenu', data, event);
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.columnApi = params.columnApi;
        },
      getColumnDefs() {
        return [
          {
            headerName: this.$t("이름"), field: "FILE_NAME", cellRendererSelector: params => {
              const filetype = params.data.FILE_TYPE;

              switch (filetype) {
                case "FILE":
                  return {
                    component: DriveGridListFile,
                    params: {
                      clipboard: this.clipboard,
                      clipboardMode: this.clipboardMode,
                    }
                  };
                case "FOLDER":
                  return {
                    component: DriveGridListFolder,
                    params: {
                      clipboard: this.clipboard,
                      clipboardMode: this.clipboardMode,
                    }
                  };
              }
            }
          },
          {headerName: this.$t("만든이"), field: "REG_USER_ID"},
          {headerName: this.$t("만든 날짜"), field: "REG_DATE", valueFormatter: this.formatDate},
          {headerName: this.$t("파일 크기"), field: "FILE_SIZE", valueFormatter: this.formatFileSize},
        ];
      },
      setHeaderNames() {
        const columnDefs = this.getColumnDefs();
        this.gridApi.setColumnDefs(columnDefs);
      }
    }
}
</script>

<style scoped>
.ag-theme-alpine {
  font-size: 20px;
  /* --ag-font-size: 18px; */
  /* --ag-borders: none; */
  --ag-foreground-color: #44403F;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-background-color: transparent;
  --ag-odd-row-background-color: transparent;
  --ag-row-hover-color: rgba(170, 170, 170, 0.2);
  --ag-alpine-active-color: #ea5414; /* active시 border color */
  --ag-selected-row-background-color: rgb(234, 84, 20, 0.2);
}
</style>
