<template>
  <div class="d-flex gap-2">
    <canvas width="38" height="38" class="bg-white rounded"
            :class="{'opacity-25': isCutItemInClipboard}"
            style="border: 1px solid #AAABAB;"
            :id="`grid-canvas-${fileKey}-0`"
            :aria-label="desc"
            role="img"/>
    <span  v-show="isPublished" style="width: 1.25rem;  height: 1.25rem;  background: #EA5414;  border-radius: 100%;  display: flex;  justify-content: center;  align-items: center;  border: 1px solid #fff;  position: absolute;  bottom: 0;  left: 40px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <circle cx="10" cy="10" r="9.75" fill="#EA5414" stroke="white" stroke-width="0.5"/>
        <path
            d="M9.79492 10C10.458 10 11.0938 9.73661 11.5627 9.26777C12.0315 8.79893 12.2949 8.16304 12.2949 7.5C12.2949 6.83696 12.0315 6.20107 11.5627 5.73223C11.0938 5.26339 10.458 5 9.79492 5C9.13188 5 8.496 5.26339 8.02715 5.73223C7.55831 6.20107 7.29492 6.83696 7.29492 7.5C7.29492 8.16304 7.55831 8.79893 8.02715 9.26777C8.496 9.73661 9.13188 10 9.79492 10V10Z"
            stroke="white" stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M13.4004 11.8705L11.6304 13.6405C11.5604 13.7105 11.4954 13.8405 11.4804 13.9355L11.3854 14.6105C11.3504 14.8555 11.5204 15.0255 11.7654 14.9905L12.4404 14.8955C12.5354 14.8805 12.6704 14.8155 12.7354 14.7455L14.5054 12.9755C14.8104 12.6705 14.9554 12.3155 14.5054 11.8655C14.0604 11.4205 13.7054 11.5655 13.4004 11.8705V11.8705Z"
            stroke="white" stroke-width="0.75" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.1445 12.125C13.2945 12.665 13.7145 13.085 14.2545 13.235" stroke="white" stroke-width="0.75"
              stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.5 15C5.5 13.065 7.425 11.5 9.795 11.5C10.315 11.5 10.815 11.575 11.28 11.715" stroke="white"
              stroke-width="0.75" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </span>
    {{ value }}
  </div>
</template>

<script>
import DTMSCanvas from "@/js/DTMSCanvas";
import {$session} from "dot-framework";

const userNo = $session.getUserNo();

export default {
  name: "DriveGridListFile",
  computed: {
    clipboard() {
      return this.params.clipboard;
    },
    clipboardMode() {
      return this.params.clipboardMode;
    },
    desc() {
      return this.params.data.DTM_DESC;
    },
    fileKey() {
      return this.params.data.FILE_KEY;
    },
    isCutItemInClipboard() {
      return this.clipboardMode?.description === "CUT" && this.clipboard.find(clipboard => clipboard.FILE_KEY === this.fileKey);
    },
    value() {
      return this.params.value;
    },
    isPublished() {
      return (this.params.data.DRIVER_KIND === 'P' && this.params.data.PUBLISH_COUNT > 0) || (this.params.data.DRIVER_KIND === 'D' && this.params.data.REG_USER_NO === userNo);
    }
  },
  mounted() {
    this.$nextTick(() => this.createDTMSCanvasOfListTypeItems());
  },
  methods: {
    createDTMSCanvasOfListTypeItems() {
      const obj = this.params.data;
      const DTMSJson = obj.DTMS_JSON;

      if (DTMSJson) {
        const pixelArr = DTMSCanvas.getPixelArray(DTMSJson.items[0]);
        const params = {
          pixelArr: pixelArr,
          canvasId: `grid-canvas-${obj.FILE_KEY}-0`,
          canvasWidth: 38,
          canvasHeight: 38
        };

        const canvas = DTMSCanvas.createCanvas(params.canvasId, params.canvasWidth, params.canvasHeight);
        canvas.add(DTMSCanvas.drawTactileDataOnTheCanvas(params.pixelArr, params.canvasWidth, params.canvasHeight));
      }
    }
  }
}
</script>
