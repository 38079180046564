<template>
  <div class="d-flex gap-2">
    <folder-icon :class="{'opacity-25': isCutItemInClipboard}" height="38" width="38"/>
    <span>{{ value }}</span>
  </div>
</template>

<script>
import FolderIcon from "@/components/atoms/FolderIcon.vue";

export default {
  name: "DriveGridListFolder",
  components: {FolderIcon},
  computed: {
    clipboard() {
      return this.params.clipboard;
    },
    clipboardMode() {
      return this.params.clipboardMode;
    },
    fileKey() {
      return this.params.data.FILE_KEY;
    },
    isCutItemInClipboard() {
      return this.clipboardMode?.description === "CUT" && this.clipboard.find(clipboard => clipboard.FILE_KEY === this.fileKey);
    },
    value() {
      return this.params.value;
    },
  },
}
</script>
